import { customerService } from '../../../../../services/customerService';
import { UserProfileFromValue } from '../UserProfileStep.settings';

export const getUserProfileFromValue = async (
  customerId: number,
): Promise<UserProfileFromValue> => {
  const { customer, user, inbodyInfo, additionallyInfo } =
    await customerService.getCustomerInfo(customerId);

  return {
    customer,
    user,
    inbodyInfo,
    additionallyInfo,
    customerGroup: null,
    scanData: undefined,
  };
};
