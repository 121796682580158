import React from 'react';

import { Control } from 'react-hook-form';

import {
  InputBox,
  LbCreatableAutocomplete,
  LbDatePicker,
  LbInput,
  LbSelect,
} from '../../../../../../../shared/components/form-components';
import {
  validateEmail,
  validatePhone,
  validateAge,
} from '../../../../../../../shared/utils/reactHookFormUtils';
import { genderOptions } from '../../../../../../../shared/constants/user-info/gender';
import { useGroupList } from '../../../../../../../customer-group';
import { UserProfileFromValue } from '../../NgzUserProfileTabFlow2024.settings';

type PersonalDataFormProps = {
  control: Control<UserProfileFromValue>;
};

const PersonalDataForm = ({ control }: PersonalDataFormProps) => {
  const generalInputProps = {
    control,
    size: 'small',
    variant: 'standard',
  } as const;

  const { groupList } = useGroupList({ onlyActive: true });

  return (
    <InputBox title="Personal Data">
      <LbInput
        {...generalInputProps}
        name="user.firstName"
        label="First Name"
        rules={{ required: 'First Name is required' }}
      />
      <LbInput
        {...generalInputProps}
        name="user.lastName"
        label="Last Name"
        rules={{ required: 'Last Name is required' }}
      />
      <LbInput
        {...generalInputProps}
        name="user.email"
        label="Email address"
        type="email"
        rules={{ required: 'Email is required', validate: { validateEmail } }}
      />
      <LbInput
        {...generalInputProps}
        name="customer.cellPhone"
        label="Cell Phone"
        type="tel"
        rules={{ required: 'Cell Phone is required', validate: { validatePhone } }}
      />
      <LbDatePicker
        {...generalInputProps}
        name="customer.dob"
        label="Date of Birth"
        helperText="You need to be at least 14 years to join the LifeBase"
        openTo="year"
        rules={{
          required: 'Date of Birth is required',
          validate: { validateMinAge: (value) => validateAge(value, 14) },
        }}
      />
      <LbSelect
        {...generalInputProps}
        name="customer.gender"
        label="Gender"
        options={genderOptions}
        rules={{ required: 'Gender is required' }}
      />
      <LbCreatableAutocomplete
        {...generalInputProps}
        name="customerGroup"
        label="User Group"
        options={groupList}
        labelKey="name"
        valueKey="id"
        prepareAddedOption={(label) => ({
          id: 0, // This is a fake id, need to know that this is a new group
          name: label,
          active: true,
          amountOfCustomers: 0,
        })}
      />
    </InputBox>
  );
};

export { PersonalDataForm };
