import React, { useMemo } from 'react';

import { Control, FieldValues, Path, useFormContext, useWatch } from 'react-hook-form';
import { useUpdateEffect } from 'react-use';
import { TextFieldProps } from '@mui/material';

import { transformToOptions } from '../../../utils/arrayUtils';
import { Sport } from '../../../interfaces/Sport';
import { ComboBox } from '../autocomplete-components/ComboBox/ComboBox';
import { LbSelect } from '../LbSelect/LbSelect';

type SelectSportPickersProps<
  T extends FieldValues,
  TSportFieldName extends Path<T>,
  TSportPositionFieldName extends Path<T>,
> = {
  control: Control<T>;
  sports: Sport[];
  sportFieldName: TSportFieldName;
  sportPositionFieldName: TSportPositionFieldName;
} & Pick<TextFieldProps, 'variant' | 'size' | 'required'>;

const SelectSportPickers = <
  T extends FieldValues,
  TSportFieldName extends Path<T>,
  TSportPositionFieldName extends Path<T>,
>({
  control,
  sports,
  sportFieldName,
  sportPositionFieldName,
  required = true,
  variant = 'standard',
  size = 'small',
}: SelectSportPickersProps<T, TSportFieldName, TSportPositionFieldName>) => {
  const generalInputProps = {
    control,
    size,
    variant,
  } as const;

  const { setValue } = useFormContext();

  const [selectedSport, selectedSportPosition] = useWatch({
    control,
    name: [sportFieldName, sportPositionFieldName],
  });

  const positionsList = useMemo(
    () => sports?.find((sport) => sport.value === selectedSport)?.positions || [],
    [selectedSport, sports],
  );

  useUpdateEffect(() => {
    const isPositionExist = positionsList.find(
      (item) => item.value === selectedSportPosition,
    );

    if (selectedSportPosition && !isPositionExist) {
      // @ts-ignore
      setValue(sportPositionFieldName, '');
    }
  }, [selectedSport]);

  return (
    <>
      <ComboBox
        {...generalInputProps}
        name={sportFieldName}
        label="Sport"
        rules={required ? { required: 'Sport is required' } : undefined}
        options={
          transformToOptions({
            list: sports || [],
            valueKey: 'value',
            labelKey: 'label',
          }) || []
        }
      />
      <LbSelect
        {...generalInputProps}
        name={sportPositionFieldName}
        label="Position"
        disabled={!selectedSport}
        rules={required ? { required: 'Position is required' } : undefined}
        options={positionsList}
      />
    </>
  );
};

export { SelectSportPickers };
