import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { angularize } from 'react-in-angularjs';
import { enqueueSnackbar } from 'notistack';

import { HiddenInput } from '../../../../../shared/components/form-components';
import { UserProfileFromValue } from './NgzUserProfileTabFlow2024.settings';
import { getUserProfileFromValue } from './utils/getUserProfileFromValue';
import { loggedInUserDataStore } from '../../../../../shared/stores/loggedInUserDataStore';
import { getRouteParam } from '../../../../../shared/utils/angularUtils';
import { InbodyInfoForm } from './components/InbodyInfoForm/InbodyInfoForm';
import { PersonalDataForm } from './components/PersonalDataForm/PersonalDataForm';
import { AdditionalInfoForm } from './components/AdditionalInfoForm/AdditionalInfoForm';
import { AddressDataFrom } from './components/AddressDataFrom/AddressDataFrom';
import { SystemProviders } from '../../../../../shared/providers';
import { ButtonsRow } from './components/ButtonsRow/ButtonsRow';
import { processAssignCustomersToGroup } from '../../../../../customer-group/utils/processAssignCustomersToGroup';
import { customerService } from '../../../../services/customerService';

const NgzUserProfileTabFlow2024 = () => {
  const customerId = getRouteParam('id');

  const formMethods = useForm<UserProfileFromValue>({
    mode: 'all',
    shouldUnregister: true,
    defaultValues: async () => getUserProfileFromValue(customerId),
  });
  const { control, handleSubmit, register } = formMethods;

  const { getEntityId } = loggedInUserDataStore;
  const entityId = getEntityId();

  const handleFormSubmit = handleSubmit(
    async ({ user, customer, inbodyInfo, additionallyInfo, customerGroup }) => {
      await Promise.all([
        processAssignCustomersToGroup(customerId, customerGroup),
        customerService.updateCustomerProfileFlow2024(customerId, {
          user,
          customer,
          inbodyInfo,
          additionallyInfo,
        }),
      ]);

      enqueueSnackbar('Customer profile has been updated successfully.', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    },
  );

  return (
    <SystemProviders>
      <FormProvider {...formMethods}>
        <HiddenInput register={register} name="user.userId" value={customerId} />
        <InbodyInfoForm register={register} control={control} />
        <PersonalDataForm control={control} />
        <AdditionalInfoForm control={control} entityId={entityId} />
        <AddressDataFrom control={control} />
        <ButtonsRow onNext={handleFormSubmit} />
      </FormProvider>
    </SystemProviders>
  );
};

angularize(
  NgzUserProfileTabFlow2024,
  'ngzUserProfileTabFlow2024',
  angular.module('app.customerManagement'),
  {},
);
