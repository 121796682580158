import React, { useEffect, useState } from 'react';

import { Container } from '@mui/material';
import { angularize } from 'react-in-angularjs';
import { useToggle } from 'react-use';

import classes from './NgzCustomerSelfSetupToEntity.module.scss';
import { SystemProviders } from '../../../shared/providers';
import { EntitySingInData } from '../../interfaces/EntitySingInData';
import { NgzPageNotFound } from '../../../shared/containers/NgzPageNotFound/NgzPageNotFound';
import { getAccessParamsFromUrl } from '../../../shared/utils/apiUtils';
import { customerSelfSetupService } from '../../services/customerSelfSetupService';
import { CompanyHeader } from './components/CompanyHeader/CompanyHeader';
import { useStepper } from '../../../shared/hooks/useStepper';
import {
  Stepper,
  StepOption,
  ExplanationSetupSteps,
} from '../../components/customer-self-setup-components';
import { QuestionnaireStep } from './containers/QuestionnaireStep/QuestionnaireStep';
import { PersonalInfoStep } from './containers/PersonalInfoStep/PersonalInfoStep';
import { SelectCoachStep } from './containers/SelectCoachStep/SelectCoachStep';
import { SelectSubscriptionStep } from './containers/SelectSubscriptionStep/SelectSubscriptionStep';
import { questionnaireStore } from '../../stores/customerSelfSetupStores/questionnaireStore';
import { personalInfoStore } from '../../stores/customerSelfSetupStores/personalInfoStore';
import { coachStore } from '../../stores/customerSelfSetupStores/coachStore';

// When make changes check if it is necessary to update the general registration flows NgzCustomerSelfSetup
const NgzCustomerSelfSetupToEntity = () => {
  const [isShowExplanation, toggleShowExplanation] = useToggle(true);
  const [entityInfo, setEntityInfo] = useState<EntitySingInData | null>(null);
  const stepperMethods = useStepper();
  const { activeStep } = stepperMethods;

  const { accessParams, allParamsExist } = getAccessParamsFromUrl();

  const getEntityInfo = async () => {
    if (allParamsExist) {
      const entityData = await customerSelfSetupService.getEntityData(accessParams);

      setEntityInfo(entityData);
    }
  };

  useEffect(() => {
    getEntityInfo();
  }, []);

  useEffect(() => {
    return () => {
      questionnaireStore.setDefaultValues();
      personalInfoStore.setDefaultValues();
      coachStore.setDefaultValues();
    };
  }, []);

  if (!allParamsExist) {
    return <NgzPageNotFound />;
  }

  if (!entityInfo) {
    return null;
  }

  const stepsConfig: StepOption[] = [
    {
      title:
        'Answer the following questions to help us create a custom meal plan specific to you and your goals',
      description: 'Please select one answer per question',
      step: (
        <QuestionnaireStep
          stepperMethods={stepperMethods}
          entityId={entityInfo.entityId}
        />
      ),
    },
    {
      title: 'Great! Now, please fill out your information to create a profile',
      description: 'All fields are required',
      step: (
        <PersonalInfoStep
          stepperMethods={stepperMethods}
          entityId={entityInfo.entityId}
        />
      ),
    },
    {
      title: 'Select coach you would like to work with',
      description: 'Use this list to find a coach, then select your coach to continue',
      step: (
        <SelectCoachStep stepperMethods={stepperMethods} entityId={entityInfo.entityId} />
      ),
    },
    {
      title: 'Select a plan below that fits your needs',
      description: 'Select a subscription and proceed to the checkout with Stripe',
      step: <SelectSubscriptionStep stepperMethods={stepperMethods} />,
    },
  ];

  return (
    <SystemProviders>
      <CompanyHeader companyLogoUrl={entityInfo.entityLogo?.fileUrl} />
      <Container maxWidth="sm" className={classes.container}>
        {isShowExplanation ? (
          <ExplanationSetupSteps onStart={toggleShowExplanation} />
        ) : (
          <Stepper activeStep={activeStep} stepsConfig={stepsConfig} />
        )}
      </Container>
    </SystemProviders>
  );
};

angularize(
  NgzCustomerSelfSetupToEntity,
  'ngzCustomerSelfSetupToEntity',
  angular.module('app.customerManagement'),
  {},
);

export { NgzCustomerSelfSetupToEntity };
