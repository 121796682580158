import { customerSelfSetupService } from '../../services/customerSelfSetupService';
import { questionnaireService } from '../../services/questionnaireService';
import { coachStore } from '../../stores/customerSelfSetupStores/coachStore';
import { personalInfoStore } from '../../stores/customerSelfSetupStores/personalInfoStore';
import { questionnaireStore } from '../../stores/customerSelfSetupStores/questionnaireStore';
import { convertUserAnswers } from '../../utils/customerSelfSetupUtils/convertUserAnswers';
import { getFilteredTruthyFieldsObj } from '../../../shared/utils/sortingAndGroupingUtil';

type UseUpsertCustomerReturn = {
  upsertCustomer: () => Promise<void>;
};

export const useUpsertCustomer = (): UseUpsertCustomerReturn => {
  const { selectedCoach } = coachStore;
  const { customerId, customerData, setCustomerId } = personalInfoStore;
  const { questions, userAnswers } = questionnaireStore;

  const upsertCustomer = async () => {
    if (!customerData || !selectedCoach) {
      throw new Error('Missing personal info or selected coach');
    }

    const customerInfo = {
      ...customerData,
      additionallyInfo: {
        ...getFilteredTruthyFieldsObj(customerData.additionallyInfo),
        sportType: customerData.additionallyInfo.sportType,
      },
      coachId: selectedCoach.coachId,
      entityId: selectedCoach.entityId,
    };

    if (customerId) {
      await customerSelfSetupService.updateCustomer(customerId, customerInfo);
      await submitUserAnswers(customerId);
    } else {
      const user = await customerSelfSetupService.createCustomer(customerInfo);
      if (user.id) {
        setCustomerId(user.id);
        await submitUserAnswers(user.id);
      }
    }
  };

  const submitUserAnswers = async (userId: number) => {
    await questionnaireService.submitUserAnswers(
      userId,
      convertUserAnswers(questions, userAnswers),
    );
  };

  return { upsertCustomer };
};
