import { useEffect, useState } from 'react';

import { Sport } from '../../../../../../shared/interfaces/Sport';
import { sportsService } from '../../../../../../shared/services/sportsService';
import { SchoolInfo } from '../../../../../../shared/interfaces/school/SchoolInfo';
import { schoolsService } from '../../../../../../shared/services/schools/schoolsService';

type UseAdditionalInfoOptionsReturns = {
  sports: Sport[];
  schools: SchoolInfo[];
};

export const useAdditionalInfoOptions = (
  entityId: number,
): UseAdditionalInfoOptionsReturns => {
  const [sports, setSports] = useState<Sport[]>([]);
  const [schools, setSchools] = useState<SchoolInfo[]>([]);

  const getAdditionalData = async () => {
    const sportsResponse = await sportsService.getSports();
    const schoolsResponse = await schoolsService.getPublicEntitySchools(entityId);

    setSports(sportsResponse);
    setSchools(schoolsResponse.customerSchoolGroups);
  };

  useEffect(() => {
    getAdditionalData();
  }, []);

  return { sports, schools };
};
